import t from '../types'

const initialState = {
  isLoading: false,
  products: [],
  productsByCat: {},
  categories: [],
  selectedProduct: {
    id: null,
    // B2C
    selectedOptions: {},
    price: '',
    extraPrice: {},
    date: null,
    studio: null,
    // B2B
    style: '',
    format: '',
    background: '',
    color: '',
    render: '',
    edit: '',
    resolution: '',
    option: '',
    // formData
    firstName: '',
    lastName: '',
    tel: '',
    email: '',
    position: '',
    company: '',
    coworkers: '',
    address: '',
    zipcode: '',
    day: '',
    month: '',
    year: '',
    // animation & reportage
    eventType: '',
    schedule: '',
    duration: '',
    usage: '',
    observations: '',
  },
  cartProduct: {}, // duplicate of selectedProduct when "AddToCart"
  extras: {},
  promoCode: null,
  paymentToken: null,
  cart: {
    products: [],
  },
  additionalPicture: {},
  orderId: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case t.GET_PRODUCTS_REQUEST:
    case t.GET_PRODUCTS_BY_CATEGORY_REQUEST:
    case t.GET_PRODUCT_BY_ID_REQUEST:
    case t.ADD_TO_CART_REQUEST:
    case t.GET_CART_REQUEST:
    case t.GET_ADDITIONAL_PICTURE_PRODUCT_BY_SLUG_REQUEST:
    case t.EMPTY_CART_REQUEST:
    case t.GET_CHECKOUT_TOKEN_REQUEST:
    case t.UPDATE_PAYMENT_REQUEST:
    case t.GET_CATEGORY_BY_SLUG_REQUEST:
    case t.GENERATE_PAYMENT_LINK_REQUEST:
    case t.NOTIFY_PAYMENT_ORDER_BY_EMAIL_REQUEST:
    case t.UPLOAD_ADDITIONAL_PICTURES_SUMMARY_REQUEST:
      return { ...state, isLoading: true }
    case t.GET_CATEGORY_BY_SLUG_SUCCESS: {
      const index = state.categories.findIndex(c => c.id === action.category.id)

      if (index === -1) {
        return {
          ...state,
          categories: [...state.categories, action.category],
          isLoading: false,
        }
      }

      const newCategories = state.categories
      newCategories[index] = action.category
      return { ...state, categories: newCategories, isLoading: false }
    }
    case t.GET_PRODUCTS_BY_CATEGORY_SUCCESS: {
      const productsById = [...state.products, ...action.products].reduce(
        (old, current) => {
          const productList = old
          productList[current.id] = Object.assign(
            old[current.id] || {},
            current
          )
          return productList
        },
        {}
      )

      const idList = []
      action.products.forEach(p => idList.push(p.id))

      return {
        ...state,
        productsByCat: {
          ...state.productsByCat,
          [action.category]: idList,
        },
        products: Object.values(productsById),
        isLoading: false,
      }
    }
    case t.GET_PRODUCT_BY_ID_SUCCESS: {
      let newProducts = state.products
      const oldProduct = state.products.find(s => s.id === action.product.id)

      if (oldProduct !== undefined) {
        newProducts = state.products.filter(p => p.id !== action.product.id)
      }

      const category =
        state.productsByCat[action.product.category[0].category_id] ===
        undefined
          ? {}
          : {
              [action.product.category[0].category_id]: [
                ...state.productsByCat[action.product.category[0].category_id],
                action.product.category[0].category_id,
              ],
            }

      return {
        ...state,
        productsByCat: { ...state.productsByCat, ...category },
        products: [...newProducts, action.product],
        isLoading: false,
      }
    }
    case t.SELECT_PRODUCT:
      return {
        ...state,
        selectedProduct: { ...state.selectedProduct, ...action.product },
      }

    case t.ADD_TO_CART_SUCCESS:
      return {
        ...state,
        cartProduct:
          action.product !== null ? action.product : state.cartProduct,
        isLoading: false,
      }
    case t.UPDATE_CART_BOOKING:
      return {
        ...state,
        cartProduct: { ...state.cartProduct, ...action.product },
      }
    case t.GET_CART_SUCCESS:
      return {
        ...state,
        cart:
          action.cart.products === undefined ? initialState.cart : action.cart,
        isLoading: false,
      }
    case t.GET_ADDITIONAL_PICTURE_PRODUCT_BY_SLUG_SUCCESS:
      return {
        ...state,
        additionalPicture:
          action.data === undefined
            ? initialState.additionalPicture
            : action.data,
        isLoading: false,
      }
    case t.UPDATE_EXTRAS: {
      const { productId, extraId } = action.payload

      if (
        state.extras[productId] !== undefined &&
        state.extras[productId].includes(extraId)
      ) {
        return {
          ...state,
          extras: {
            ...state.extras,
            [productId]: state.extras[productId].filter(i => i !== extraId),
          },
        }
      }
      return {
        ...state,
        extras: {
          ...state.extras,
          [productId]:
            state.extras[productId] !== undefined
              ? [...state.extras[productId], extraId]
              : [extraId],
        },
      }
    }
    case t.GET_CHECKOUT_TOKEN_SUCCESS:
      return { ...state, paymentToken: action.data, isLoading: false }
    case t.UPDATE_PAYMENT_SUCCESS:
      return { ...state, orderId: action.orderId, isLoading: false }
    case t.GET_CATEGORY_BY_SLUG_FAILURE:
    case t.GET_PRODUCTS_BY_CATEGORY_FAILURE:
    case t.GET_PRODUCT_BY_ID_FAILURE:
    case t.GET_CART_FAILURE:
    case t.GET_ADDITIONAL_PICTURE_PRODUCT_BY_SLUG_FAILURE:
    case t.ADD_TO_CART_FAILURE:
    case t.EMPTY_CART_SUCCESS:
      return { ...state, extras: {} }
    case t.GENERATE_PAYMENT_LINK_SUCCESS:
    case t.NOTIFY_PAYMENT_ORDER_BY_EMAIL_SUCCESS:
    case t.UPLOAD_ADDITIONAL_PICTURES_SUMMARY_SUCCESS:
    case t.EMPTY_CART_FAILURE:
    case t.GET_CHECKOUT_TOKEN_FAILURE:
    case t.UPDATE_PAYMENT_FAILURE:
    case t.GENERATE_PAYMENT_LINK_FAILURE:
    case t.NOTIFY_PAYMENT_ORDER_BY_EMAIL_FAILURE:
    case t.UPLOAD_ADDITIONAL_PICTURES_SUMMARY_FAILURE:
      return { ...state, isLoading: false }
    default:
      return state
  }
}
