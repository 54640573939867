import { combineReducers } from 'redux'

import authReducer from './authentication/reducer'
import projectReducer from './projects/reducer'
import collaboratorReducer from './collaborators/reducer'
import studioReducer from './studios/reducer'
import offerReducer from './offer/reducer'

export default combineReducers({
  auth: authReducer,
  project: projectReducer,
  collaborator: collaboratorReducer,
  studio: studioReducer,
  offer: offerReducer,
})
