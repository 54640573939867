import t from '../types'

const initialState = {
  isLoading: false,
  projects: [],
  photos: {},
  error: {
    getProjectList: false,
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case t.GET_PROJECTS_REQUEST:
      return { ...state, isLoading: true }
    case t.GET_PROJECTS_SUCCESS:
      return { ...state, projects: action.projects, isLoading: false }
    case t.GET_PROJECTS_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          getProjectList: action.error,
        },
        isLoading: false,
      }
    case t.GET_REPORTAGE_ANIMATION_PICTURES_REQUEST:
      return { ...state, isLoading: true }
    case t.GET_REPORTAGE_ANIMATION_PICTURES_SUCCESS:
      return { ...state, photos: action.photos, isLoading: false }
    case t.GET_REPORTAGE_ANIMATION_PICTURES_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          getProjectList: action.error,
        },
        photos: {
          ...state.photos,
          [action.projectId]: action.photos,
        },
        isLoading: false,
      }
    case t.LOGOUT_SUCCESS:
      return initialState
    default:
      return state
  }
}
