export default {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  SIGNUP_REQUEST: 'SIGNUP_REQUEST',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_FAILURE: 'SIGNUP_FAILURE',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
  GET_PROJECTS_REQUEST: 'GET_PROJECTS_REQUEST',
  GET_PROJECTS_SUCCESS: 'GET_PROJECTS_SUCCESS',
  GET_PROJECTS_FAILURE: 'GET_PROJECTS_FAILURE',
  GET_REPORTAGE_ANIMATION_PICTURES_REQUEST:
    'GET_REPORTAGE_ANIMATION_PICTURES_REQUEST',
  GET_REPORTAGE_ANIMATION_PICTURES_SUCCESS:
    'GET_REPORTAGE_ANIMATION_PICTURES_SUCCESS',
  GET_REPORTAGE_ANIMATION_PICTURES_FAILURE:
    'GET_REPORTAGE_ANIMATION_PICTURES_FAILURE',
  GET_COLLABORATOR_PICTURES_REQUEST: 'GET_COLLABORATOR_PICTURES_REQUEST',
  GET_COLLABORATOR_PICTURES_SUCCESS: 'GET_COLLABORATOR_PICTURES_SUCCESS',
  GET_COLLABORATOR_PICTURES_FAILURE: 'GET_COLLABORATOR_PICTURES_FAILURE',
  SELECT_PICTURE_REQUEST: 'SELECT_PICTURE_REQUEST',
  SELECT_PICTURE_SUCCESS: 'SELECT_PICTURE_SUCCESS',
  SELECT_PICTURE_FAILURE: 'SELECT_PICTURE_FAILURE',
  DOWNLOAD_PICTURE_REQUEST: 'DOWNLOAD_PICTURE_REQUEST',
  DOWNLOAD_PICTURE_SUCCESS: 'DOWNLOAD_PICTURE_SUCCESS',
  DOWNLOAD_PICTURE_FAILURE: 'DOWNLOAD_PICTURE_FAILURE',
  DOWNLOAD_PICTURES_REQUEST: 'DOWNLOAD_PICTURES_REQUEST',
  DOWNLOAD_PICTURES_SUCCESS: 'DOWNLOAD_PICTURES_SUCCESS',
  DOWNLOAD_PICTURES_FAILURE: 'DOWNLOAD_PICTURES_FAILURE',
  DOWNLOAD_REPORTAGE_ANIMATION_PICTURES_REQUEST:
    'DOWNLOAD_REPORTAGE_ANIMATION_PICTURES_REQUEST',
  DOWNLOAD_REPORTAGE_ANIMATION_PICTURES_SUCCESS:
    'DOWNLOAD_REPORTAGE_ANIMATION_PICTURES_SUCCESS',
  DOWNLOAD_REPORTAGE_ANIMATION_PICTURES_FAILURE:
    'DOWNLOAD_REPORTAGE_ANIMATION_PICTURES_FAILURE',
  DOWNLOAD_PROJECT_PICTURES_REQUEST: 'DOWNLOAD_PROJECT_PICTURES_REQUEST',
  DOWNLOAD_PROJECT_PICTURES_SUCCESS: 'DOWNLOAD_PROJECT_PICTURES_SUCCESS',
  DOWNLOAD_PROJECT_PICTURES_FAILURE: 'DOWNLOAD_PROJECT_PICTURES_FAILURE',
  UPDATE_ACCOUNT_REQUEST: 'UPDATE_ACCOUNT_REQUEST',
  UPDATE_ACCOUNT_SUCCESS: 'UPDATE_ACCOUNT_SUCCESS',
  UPDATE_ACCOUNT_FAILURE: 'UPDATE_ACCOUNT_FAILURE',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  CHECK_TOKEN_REQUEST: 'CHECK_TOKEN_REQUEST',
  CHECK_TOKEN_SUCCESS: 'CHECK_TOKEN_SUCCESS',
  CHECK_TOKEN_FAILURE: 'CHECK_TOKEN_FAILURE',
  SET_PASSWORD_REQUEST: 'SET_PASSWORD_REQUEST',
  SET_PASSWORD_SUCCESS: 'SET_PASSWORD_SUCCESS',
  SET_PASSWORD_FAILURE: 'SET_PASSWORD_FAILURE',
  SEND_COLLABORATORS_REQUEST: 'SEND_COLLABORATORS_REQUEST',
  SEND_COLLABORATORS_SUCCESS: 'SEND_COLLABORATORS_SUCCESS',
  SEND_COLLABORATORS_FAILURE: 'SEND_COLLABORATORS_FAILURE',
  GET_COLLABORATORS_REQUEST: 'GET_COLLABORATORS_REQUEST',
  GET_COLLABORATORS_SUCCESS: 'GET_COLLABORATORS_SUCCESS',
  GET_COLLABORATORS_FAILURE: 'GET_COLLABORATORS_FAILURE',
  DELETE_COLLABORATOR_REQUEST: 'DELETE_COLLABORATOR_REQUEST',
  DELETE_COLLABORATOR_SUCCESS: 'DELETE_COLLABORATOR_SUCCESS',
  DELETE_COLLABORATOR_FAILURE: 'DELETE_COLLABORATOR_FAILURE',
  GET_STUDIOS_REQUEST: 'GET_STUDIOS_REQUEST',
  GET_STUDIOS_SUCCESS: 'GET_STUDIOS_SUCCESS',
  GET_STUDIOS_FAILURE: 'GET_STUDIOS_FAILURE',
  GET_STUDIOS_BY_PRODUCT_REQUEST: 'GET_STUDIOS_BY_PRODUCT_REQUEST',
  GET_STUDIOS_BY_PRODUCT_SUCCESS: 'GET_STUDIOS_BY_PRODUCT_SUCCESS',
  GET_STUDIOS_BY_PRODUCT_FAILURE: 'GET_STUDIOS_BY_PRODUCT_FAILURE',
  GET_STUDIO_TIMESLOTS_REQUEST: 'GET_STUDIO_TIMESLOTS_REQUEST',
  GET_STUDIO_TIMESLOTS_SUCCESS: 'GET_STUDIO_TIMESLOTS_SUCCESS',
  GET_STUDIO_TIMESLOTS_FAILURE: 'GET_STUDIO_TIMESLOTS_FAILURE',
  CONFIRM_STUDIO_SESSION_REQUEST: 'CONFIRM_STUDIO_SESSION_REQUEST',
  CONFIRM_STUDIO_SESSION_SUCCESS: 'CONFIRM_STUDIO_SESSION_SUCCESS',
  CONFIRM_STUDIO_SESSION_FAILURE: 'CONFIRM_STUDIO_SESSION_FAILURE',
  CONFIRM_B2C_STUDIO_SESSION_REQUEST: 'CONFIRM_B2C_STUDIO_SESSION_REQUEST',
  CONFIRM_B2C_STUDIO_SESSION_SUCCESS: 'CONFIRM_B2C_STUDIO_SESSION_SUCCESS',
  CONFIRM_B2C_STUDIO_SESSION_FAILURE: 'CONFIRM_B2C_STUDIO_SESSION_FAILURE',
  UPDATE_STUDIO_SESSION_REQUEST: 'UPDATE_STUDIO_SESSION_REQUEST',
  UPDATE_STUDIO_SESSION_SUCCESS: 'UPDATE_STUDIO_SESSION_SUCCESS',
  UPDATE_STUDIO_SESSION_FAILURE: 'UPDATE_STUDIO_SESSION_FAILURE',
  CANCEL_STUDIO_SESSION_REQUEST: 'CANCEL_STUDIO_SESSION_REQUEST',
  CANCEL_STUDIO_SESSION_SUCCESS: 'CANCEL_STUDIO_SESSION_SUCCESS',
  CANCEL_STUDIO_SESSION_FAILURE: 'CANCEL_STUDIO_SESSION_FAILURE',
  GET_STUDIO_SESSION_LIST_REQUEST: 'GET_STUDIO_SESSION_LIST_REQUEST',
  GET_STUDIO_SESSION_LIST_SUCCESS: 'GET_STUDIO_SESSION_LIST_SUCCESS',
  GET_STUDIO_SESSION_LIST_FAILURE: 'GET_STUDIO_SESSION_LIST_FAILURE',
  GET_COLLABORATORS_ADDED_SUCCESS: 'GET_COLLABORATORS_ADDED_SUCCESS',
  GET_CART_REQUEST: 'GET_CART_REQUEST',
  GET_CART_SUCCESS: 'GET_CART_SUCCESS',
  GET_CART_FAILURE: 'GET_CART_FAILURE',
  ADD_TO_CART_REQUEST: 'ADD_TO_CART_REQUEST',
  ADD_TO_CART_SUCCESS: 'ADD_TO_CART_SUCCESS',
  ADD_TO_CART_FAILURE: 'ADD_TO_CART_FAILURE',
  ADD_COUPON_REQUEST: 'ADD_COUPON_REQUEST',
  ADD_COUPON_SUCCESS: 'ADD_COUPON_SUCCESS',
  REMOVE_COUPON_REQUEST: 'REMOVE_COUPON_REQUEST',
  REMOVE_COUPON_SUCCESS: 'REMOVE_COUPON_SUCCESS',
  EMPTY_CART_REQUEST: 'EMPTY_CART_REQUEST',
  EMPTY_CART_SUCCESS: 'EMPTY_CART_SUCCESS',
  EMPTY_CART_FAILURE: 'EMPTY_CART_FAILURE',
  DELETE_CART_PRODUCT_REQUEST: 'DELETE_CART_PRODUCT_REQUEST',
  DELETE_CART_PRODUCT_SUCCESS: 'DELETE_CART_PRODUCT_SUCCESS',
  DELETE_CART_PRODUCT_FAILURE: 'DELETE_CART_PRODUCT_FAILURE',
  UPDATE_CART_BOOKING: 'UPDATE_CART_BOOKING',
  GET_CHECKOUT_TOKEN_REQUEST: 'GET_CHECKOUT_TOKEN_REQUEST',
  GET_CHECKOUT_TOKEN_SUCCESS: 'GET_CHECKOUT_TOKEN_SUCCESS',
  GET_CHECKOUT_TOKEN_FAILURE: 'GET_CHECKOUT_TOKEN_FAILURE',
  GET_CHECKOUT_TOKEN_RC_REQUEST: 'GET_CHECKOUT_TOKEN_RC_REQUEST',
  GET_CHECKOUT_TOKEN_RC_SUCCESS: 'GET_CHECKOUT_TOKEN_RC_SUCCESS',
  GET_CHECKOUT_TOKEN_RC_FAILURE: 'GET_CHECKOUT_TOKEN_RC_FAILURE',
  GET_PRODUCTS_REQUEST: 'GET_PRODUCTS_REQUEST',
  GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_FAILURE: 'GET_PRODUCTS_FAILURE',
  GET_CATEGORY_BY_SLUG_REQUEST: 'GET_CATEGORY_BY_SLUG_REQUEST',
  GET_CATEGORY_BY_SLUG_SUCCESS: 'GET_CATEGORY_BY_SLUG_SUCCESS',
  GET_CATEGORY_BY_SLUG_FAILURE: 'GET_CATEGORY_BY_SLUG_FAILURE',
  GET_PRODUCTS_BY_CATEGORY_REQUEST: 'GET_PRODUCTS_BY_CATEGORY_REQUEST',
  GET_PRODUCTS_BY_CATEGORY_SUCCESS: 'GET_PRODUCTS_BY_CATEGORY_SUCCESS',
  GET_PRODUCTS_BY_CATEGORY_FAILURE: 'GET_PRODUCTS_BY_CATEGORY_FAILURE',
  GET_PRODUCT_BY_ID_REQUEST: 'GET_PRODUCT_BY_ID_REQUEST',
  GET_PRODUCT_BY_ID_SUCCESS: 'GET_PRODUCT_BY_ID_SUCCESS',
  GET_PRODUCT_BY_ID_FAILURE: 'GET_PRODUCT_BY_ID_FAILURE',
  SELECT_PRODUCT: 'SELECT_PRODUCT',
  CREATE_B2B_LEAD_REQUEST: 'CREATE_B2B_LEAD_REQUEST',
  CREATE_B2B_LEAD_SUCCESS: 'CREATE_B2B_LEAD_SUCCESS',
  CREATE_B2B_LEAD_FAILURE: 'CREATE_B2B_LEAD_FAILURE',
  UPDATE_EXTRAS: 'UPDATE_EXTRAS',
  UPDATE_PAYMENT_REQUEST: 'UPDATE_PAYMENT_REQUEST',
  UPDATE_PAYMENT_SUCCESS: 'UPDATE_PAYMENT_SUCCESS',
  UPDATE_PAYMENT_FAILURE: 'UPDATE_PAYMENT_FAILURE',
  UPDATE_CLIENT_AND_COLLABORATOR_REQUEST:
    'UPDATE_CLIENT_AND_COLLABORATOR_REQUEST',
  UPDATE_CLIENT_AND_COLLABORATOR_SUCCESS:
    'UPDATE_CLIENT_AND_COLLABORATOR_SUCCESS',
  UPDATE_CLIENT_AND_COLLABORATOR_FAILURE:
    'UPDATE_CLIENT_AND_COLLABORATOR_FAILURE',
  UPLOAD_ADDITIONAL_PICTURES_SUMMARY_REQUEST:
    'UPLOAD_ADDITIONAL_PICTURES_SUMMARY_REQUEST',
  UPLOAD_ADDITIONAL_PICTURES_SUMMARY_SUCCESS:
    'UPLOAD_ADDITIONAL_PICTURES_SUMMARY_SUCCESS',
  UPLOAD_ADDITIONAL_PICTURES_SUMMARY_FAILURE:
    'UPLOAD_ADDITIONAL_PICTURES_SUMMARY_FAILURE',
  NOTIFY_PAYMENT_ORDER_BY_EMAIL_REQUEST:
    'NOTIFY_PAYMENT_ORDER_BY_EMAIL_REQUEST',
  NOTIFY_PAYMENT_ORDER_BY_EMAIL_SUCCESS:
    'NOTIFY_PAYMENT_ORDER_BY_EMAIL_SUCCESS',
  NOTIFY_PAYMENT_ORDER_BY_EMAIL_FAILURE:
    'NOTIFY_PAYMENT_ORDER_BY_EMAIL_FAILURE',
  GENERATE_PAYMENT_LINK_REQUEST: 'GENERATE_PAYMENT_LINK_REQUEST',
  GENERATE_PAYMENT_LINK_SUCCESS: 'GENERATE_PAYMENT_LINK_SUCCESS',
  GENERATE_PAYMENT_LINK_FAILURE: 'GENERATE_PAYMENT_LINK_FAILURE',
  CREATE_ORDER_REQUEST: 'CREATE_ORDER_REQUEST',
  CREATE_ORDER_SUCCESS: 'CREATE_ORDER_SUCCESS',
  CREATE_ORDER_FAILURE: 'CREATE_ORDER_FAILURE',
  GET_ORDER_RC_REQUEST: 'GET_ORDER_RC_REQUEST',
  GET_ORDER_RC_SUCCESS: 'GET_ORDER_RC_SUCCESS',
  GET_ORDER_RC_FAILURE: 'GET_ORDER_RC_FAILURE',
  GET_ADDITIONAL_PICTURE_PRODUCT_BY_SLUG_REQUEST:
    'GET_ADDITIONAL_PICTURE_PRODUCT_BY_SLUG_REQUEST',
  GET_ADDITIONAL_PICTURE_PRODUCT_BY_SLUG_SUCCESS:
    'GET_ADDITIONAL_PICTURE_PRODUCT_BY_SLUG_SUCCESS',
  GET_ADDITIONAL_PICTURE_PRODUCT_BY_SLUG_FAILURE:
    'GET_ADDITIONAL_PICTURE_PRODUCT_BY_SLUG_FAILURE',
}
