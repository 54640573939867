import t from '../types'

const initialState = {
  isLoading: false,
  user: null,
  success: { changePassword: false, resetPassword: false },
  error: { login: false, resetPassword: false },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case t.LOGIN_REQUEST:
    case t.CHANGE_PASSWORD_REQUEST:
    case t.RESET_PASSWORD_REQUEST:
      return { ...state, isLoading: true }
    case t.LOGIN_SUCCESS:
      return {
        ...state,
        user: { ...state.user, ...action.user },
        isLoading: false,
      }
    case t.LOGOUT_SUCCESS:
      return { ...state, user: null }
    case t.SIGNUP_SUCCESS:
      return { ...state }
    case t.CHANGE_PASSWORD_SUCCESS:
      return { ...state, success: { ...state.success, changePassword: true } }
    case t.RESET_PASSWORD_SUCCESS:
      return { ...state, success: { ...state.success, resetPassword: true } }
    case 'UPDATE_USER_ADDRESS':
      return { ...state, user: { ...state.user, ...action.address } }
    case t.LOGIN_FAILURE:
      return { ...state, error: { ...state.error, login: action.error } }
    case t.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        error: { ...state.error, changePassword: action.error },
      }
    case t.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        error: { ...state.error, resetPassword: action.error },
      }
    default:
      return state
  }
}
