import t from '../types'

const initialState = {
  isLoading: false,
  collaborators: {
    // [projectId]:[{},{}...]
  },
  pictures: {
    // [collabId]:{projectId:[pictures]}
  },
  collaboratorsAdded: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case t.GET_COLLABORATOR_PICTURES_REQUEST:
    case t.GET_COLLABORATORS_REQUEST:
    case t.DELETE_COLLABORATOR_REQUEST:
    case t.DOWNLOAD_PICTURE_REQUEST:
    case t.DOWNLOAD_PICTURES_REQUEST:
      return { ...state, isLoading: true }
    case t.GET_COLLABORATOR_PICTURES_SUCCESS: {
      // not found
      return {
        ...state,
        pictures: {
          ...state.pictures,
          [action.collaboratorId]: {
            ...state.pictures[action.collaboratorId],
            [action.projectId]: action.pictures,
          },
        },
        isLoading: false,
      }
    }
    case t.GET_COLLABORATORS_SUCCESS:
      return {
        ...state,
        collaborators: {
          ...state.collaborators,
          [action.projectId]: action.collaborators,
        },
        isLoading: false,
      }
    case t.UPDATE_CLIENT_AND_COLLABORATOR_REQUEST:
      return { ...state, isLoading: true }
    case t.UPDATE_CLIENT_AND_COLLABORATOR_SUCCESS:
      return { ...state, isLoading: false }
    case t.UPDATE_CLIENT_AND_COLLABORATOR_FAILURE:
      return { ...state, isLoading: false }
    case t.GET_COLLABORATORS_FAILURE:
    case t.DELETE_COLLABORATOR_FAILURE:
    case t.DOWNLOAD_PICTURE_FAILURE:
    case t.DOWNLOAD_PICTURES_FAILURE:
    case t.DELETE_COLLABORATOR_SUCCESS:
    case t.DOWNLOAD_PICTURE_SUCCESS:
    case t.DOWNLOAD_PICTURES_SUCCESS:
      return { ...state, isLoading: false }
    case t.LOGOUT_SUCCESS:
      return initialState
    case t.GET_COLLABORATORS_ADDED_SUCCESS:
      return {
        ...state,
        collaboratorsAdded: action.collaborators,
      }
    default:
      return state
  }
}
