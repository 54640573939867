import t from '../types'

const initialState = {
  isLoading: false,
  studios: [],
  timeslots: {},
  sessions: [],
  sessionsByProject: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case t.GET_STUDIOS_REQUEST:
    case t.GET_STUDIO_TIMESLOTS_REQUEST:
    case t.GET_STUDIO_SESSION_LIST_REQUEST:
    case t.CONFIRM_STUDIO_SESSION_REQUEST:
    case t.UPDATE_STUDIO_SESSION_REQUEST:
    case t.CANCEL_STUDIO_SESSION_REQUEST:
      return { ...state, isLoading: true }
    case t.GET_STUDIOS_SUCCESS:
      return { ...state, studios: action.studios, isLoading: false }
    case t.GET_STUDIO_TIMESLOTS_SUCCESS:
      return { ...state, timeslots: action.timeslots, isLoading: false }
    case t.CONFIRM_STUDIO_SESSION_SUCCESS:
    case t.UPDATE_STUDIO_SESSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sessionsByProject: {
          ...state.sessionsByProject,
          [action.session.project_id]: action.session,
        },
        sessions: [...state.sessions, action.session],
      }
    case t.CANCEL_STUDIO_SESSION_SUCCESS: {
      const oldSession = state.sessions.find(s => s.id === action.session)
      if (typeof oldSession !== 'undefined') {
        const {
          [oldSession.project_id]: oldVal,
          ...otherProjects
        } = state.sessionsByProject
        return {
          ...state,
          isLoading: false,
          sessionsByProject: otherProjects,
        }
      }
      return state
    }
    case t.GET_STUDIO_SESSION_LIST_SUCCESS: {
      let newList = {}
      if (action.sessions.length === 0)
        return {
          ...state,
          isLoading: false,
          sessionsByProject: {},
          sessions: [],
        }
      action.sessions.forEach(session => {
        newList = {
          ...newList,
          [session.project_id]: session,
        }
      })
      return {
        ...state,
        isLoading: false,
        sessionsByProject: newList,
        sessions: action.sessions,
      }
    }
    case t.GET_STUDIOS_FAILURE:
    case t.GET_STUDIO_TIMESLOTS_FAILURE:
    case t.GET_STUDIO_SESSION_LIST_FAILURE:
    case t.CONFIRM_STUDIO_SESSION_FAILURE:
    case t.UPDATE_STUDIO_SESSION_FAILURE:
    case t.CANCEL_STUDIO_SESSION_FAILURE:
      return { ...state, isLoading: false }
    default:
      return state
  }
}
