import React from 'react'

import { applyMiddleware, createStore } from 'redux'

import { Provider } from 'react-redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import storage from 'redux-persist/lib/storage'

import propTypes from 'prop-types'
import rootReducer from './rootReducer'

const loggerMiddleware = createLogger()

const persistConfig = {
  key: `${process.env.NODE_ENV}_booke`,
  storage,
}

const store = createStore(
  persistReducer(persistConfig, rootReducer),
  applyMiddleware(thunkMiddleware, loggerMiddleware)
)

const persistor = persistStore(store)

const ReduxWrapper = ({ element }) => (
  <Provider store={store}>{element}</Provider>
)

ReduxWrapper.propTypes = {
  element: propTypes.element.isRequired,
}

export const PersistedWrapper = ({ element }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {element}
    </PersistGate>
  </Provider>
)

PersistedWrapper.propTypes = {
  element: propTypes.element.isRequired,
}

export default ReduxWrapper
